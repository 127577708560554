<template>
  <div v-if="pageReady" id="reset-password">
    <div class="container">
      <div class="row m-5">
        <div v-if="isSuccess" class="col-lg-6 col-md-6 col-sm-12 m-auto">
          <div class="box">
            <p class="text-center">
              <img class="status-icon" src="@/assets/images/success_tick.svg">
            </p>
            <p class="text-center">
              Şifreniz başarılı şekilde değiştirildi.<br><br>
              Yeni şifrenizle uygulamaya giriş yapabilirsiniz.
            </p>
          </div>
        </div>
        <div v-else-if="isFail" class="col-lg-6 col-md-6 col-sm-12 m-auto">
          <div class="box">
            <p class="text-center">
              <img class="status-icon" src="@/assets/images/fail_icon.svg">
            </p>
            <p class="text-center">
              Hata!<br><br>
              İşlem sırasında bir hata meydana geldi.<br>
              Dilerseniz <b>info@nettahmin.com</b> adresinden bizimle iletişime geçebilirsiniz.
            </p>
          </div>
        </div>
        <div v-else class="col-lg-6 col-md-6 col-sm-12 m-auto">
          <div v-if="!isCodeEmpty" class="box">
            <h4>Şifremi Yenile</h4>
            <div class="form-group pt-3">
              <label>Yeni Şifreniz</label>
              <input v-model="resetPasswordRequest.data.password"
                     class="form-control" type="password">
            </div>
            <div @click="submitResetPassword" class="btn btn-primary">
              <b-spinner v-if="loader" small variant="light"></b-spinner>
              <span v-else>Onayla</span>
            </div>
          </div>
          <div v-else class="box">
            <p>Geçersiz Kod</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ResetPassword',
  data() {
    return {
      pageReady: false,
      loader: false,
    };
  },
  created() {
    if (this.$route.query.code) {
      this.resetPasswordRequest.data.code = this.$route.query.code;
    }

    this.pageReady = true;
  },
  computed: {
    ...mapState({
      resetPasswordRequest: (state) => state.user.resetPasswordRequest,
    }),
    isCodeEmpty() {
      return typeof this.resetPasswordRequest.data.code === 'undefined' || this.resetPasswordRequest.data.code.length < 1;
    },
    isSuccess() {
      return this.resetPasswordRequest.response.result === 'success';
    },
    isFail() {
      return this.resetPasswordRequest.response.result === 'fail';
    },
  },
  methods: {
    async submitResetPassword() {
      this.loader = true;
      await this.$store.dispatch('user/resetPassword');
    },
  },
};
</script>
