<template>
  <footer>
    <div class="footer-area footer-area2">
      <div class="footer-top padding-top-100 padding-bottom-70">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="widget text-center text-sm-left">
                <div class="f-logo">
                  <img src="@/assets/images/NetTahminLogoDark@1x.svg">
                </div>
                <p>Bahis Tahmin Platformu</p>
                <div class="social-icons">
                  <span class="si3"><a href="https://www.instagram.com/nettahminapp"><i class="fab fa-instagram"></i></a></span>
                  <span class="si2"><a href="https://twitter.com/nettahmin"><i class="fab fa-twitter"></i></a></span>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="widget text-center text-sm-left">
                <ul class="footer-menu">
                  <li><a href="/user-agreement">Kullanıcı Sözleşmesi</a></li>
                  <li><a href="/privacy-policy">Gizlilik Politikası</a></li>
                  <li><a href="/social-media-policy">Sosyal Platform Politikası</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="widget text-center text-sm-left">
                <h6>İLETİŞİM</h6>
                <ul>
                  <li><i class="fas fa-envelope"></i> info@nettahmin.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="copyright-area d-flex justify-content-center align-items-center padding-15">
          <p>&copy; Copyright Net Tahmin 2022 All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>

</style>
