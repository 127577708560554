<template>
  <div id="app">
    <Header></Header>
    <router-view class="content"/>
    <Footer></Footer>
  </div>
</template>

<style>
.content {
  min-height: 50vh;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: { Footer, Header },
};
</script>
