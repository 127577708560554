<template>
  <div id="social-policy">
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 box">
          <h4 class="text-center m-3">Social Media Policy</h4>

          <p>Net Tahmin would like its presence on social media sites to be honest,
            open, and respectful. In an effort to be transparent about
            our social media policies, we share our social media guidelines here.</p>

          <p>Together We Create an Open, Respectful Place for Betting and Sports:
            Net Tahmin encourages lively and discussion in its networking
            social media platforms, but will not tolerate comments
            which are off topic, abusive, obscene, defamatory, vulgar,
            threatening, or which invade privacy or are libelous,
            including as described below. Offenders will be banned from posting.
            We will also remove posts that include gross errors of fact.
            Any member may suggest removal of any comment
            which appears to violate this policy.</p>

          <p>Comments are Subject to Public Disclosure All comments
            submitted or posted on Net Tahmin's social media accounts
            are public. Net Tahmin reserves the right to restrict or
            remove any content that violates these policies or any
            applicable law.</p>

          <p>Comments by Others are NOT Endorsed Net Tahmin does not
            necessarily endorse, support, sanction, encourage, verify,
            or agree with the comments, opinions or statements posted
            on its social media accounts. Any information or material
            placed online, including advice and opinions, are the views
            and responsibility of those making the comments and do not
            necessarily represent the views of Net Tahmin.
            By submitting a comment for posting, you agree that Net Tahmin
            is not responsible, and shall have no liability to you,
            with respect to any information or materials posted,
            including defamatory, offensive, or illicit material,
            even material that violates this policy.</p>

          <p>The Right to Report Threatening Comments Net Tahmin must
            also reserve the right to report any comments that imply
            harm or imminent danger to yourself or others.
            By submitting a comment for posting, you accept
            that Net Tahmin has the right to contact the appropriate
            authorities to report any suspicious activities,
            threats or behaviors.</p>

          <p>Editing and Deletions Net Tahmin reserves the right,
            but undertakes no duty, to review, edit, move, or
            delete any material submitted as a comment to information
            provided for display or posted on its social media accounts
            in its sole discretion, without notice.
            Comments submitted to these sites will be reviewed
            and posted on the website if they meet our comment policy.
            Although Net Tahmin will monitor all websites on a regular
            basis for inappropriate or defamatory content,
            comments submitted after regular business hours,
            Monday through Friday, will be read and posted or deleted as early as possible.</p>

          <p>Inappropriate comments will be deleted at the sole
            discretion of Net Tahmin. While we encourage submissions
            from all viewpoints, we ask that all participants
            adhere to the following Terms of Participation:</p>

          <p>To ensure constructive networking please post only questions
            or comments directly related and relevant to
            Net Tahmin and the topics discussed. Do not post
            questions that contain threats, obscenities, and any
            other material that would violate the law if published,
            such as abusive, defamatory, or sexually explicit material.
            Submissions containing the following will be deleted:
            Obscene or threatening language or discrimination (hate speech)
            based on race, sex, gender, religion, national origin,
            age or disability Commercial services or products
            Any personal or sensitive information
            (phone numbers, e-mail, or postal addresses)
            Any endorsement of religious beliefs/practices or political parties,
            candidates, or groups Any posts or links
            intended to influence (lobby) a Member of Congress or government
            official on legislation or appropriation In the event
            that your original post contains an appropriate resource,
            Net Tahmin may ask you to modify your posting
            by removing any portion that breaches this policy.</p>

          <p>Appropriate URL Format Net Tahmin reserves the right to
            remove any URLs that pose or appear to pose a security
            threat to its site, the United States Government and
            other users, including, but not limited to,
            any URLs that have been shortened by third-party sites
            (i.e. bit.ly, tiny.cc). Since Net Tahmin will actively check
            and monitor all links, users may only post content
            containing a full web address. The only exception to
            this rule are sites that limit characters, such as Twitter.
            Users are advised to click on third-party content at their own risk.</p>

          <p>External Links (including advertising links) The posting
            of external hyperlinks does not constitute endorsement
            by Net Tahmin of the linked Web sites, or the information,
            products, or services contained there. Net Tahmin does
            not exercise editorial control over the information you
            may find at these locations. All links are provided
            consistent with the stated purpose of Net Tahmin.</p>

          <p>Accessibility Third-party social media platforms and
            Web sites may not be accessible to all users, including
            those with disabilities. Whenever possible, information
            posted by Net Tahmin on its social media accounts will
            be available on the official Net Tahmin application
            in alternative formats.</p>

          <p>Disclaimer Reference herein to any specific commercial products,
            process, or service by trade name, trademark, manufacturer,
            or otherwise, does not necessarily constitute or imply endorsement,
            recommendation, or favoring by Net Tahmin. The views and
            opinions of authors expressed herein do not necessarily
            state or reflect those of Net Tahmin or its funders, and
            shall not be used for advertising or product endorsement
            purposes. Net Tahmin assumes no legal liability or
            responsibility for the accuracy, completeness, or usefulness
            of any information, apparatus, product, or process mentioned
            on its applications.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialPolicy',
};
</script>

<style scoped>

</style>
