<template>
  <div id="privacy-policy">
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 box">
          <h4 class="text-center m-3">Net Tahmin Privacy Policy</h4>

          <p><b>All Users and Subscribers are required to read, acknowledge and accept the
            responsibilities and rules defined in the following Agreement prior to using
            Net Tahmin Mobile Application, and benefiting from the services provided.
            In using the app, Users and Subscribers have willingly and fully acknowledged
            and accepted its responsibilities and rules.</b></p>

          <p>1. PRIVACY STATEMENT 1.1. Net Tahmin, in order to ensure User and Subscriber
            personal and institutional privacy, while providing the maximum benefit of its
            technology services, has adopted various policies for individual and institutional
            protection and data security. This privacy policy is determined and declared to
            be applicable for all data collection and/or use on mobile applications,
            Internet sites, and services provided by Net Tahmin and connected to Net Tahmin.</p>

          <p>1.2. Users and Subscribers are deemed to have accepted the policies related to
            visiting and/or using platforms and mobile applications provided by Net Tahmin
            and connected to Net Tahmin as a visitor, User or Subscriber. Net Tahmin has the
            right to change, correct and update its "Privacy Statement" on its platforms
            with or without prior notice. Users and Subscribers automatically agree to the
            changes by using the platforms and Mobile Applications.</p>

          <p>1.3. Net Tahmin shall not reveal personal or institutional information and/or
            data provided by Users and Subscribers on its platforms and mobile applications,
            or provide it to third parties. Net Tahmin has the right to issue notices,
            market and/or collect statistical data as regulated by law. Net Tahmin declares
            and undertakes not to communicate personal or institutional information without
            its Users and Subscribers consent, and agree to provide tools enabling Users and
            Subscribers to leave the system easily and free of charge. Net Tahmin will not share,
            sell or allow the use of User and Subscriber data and information by any third party
            under any conditions except for purposes regarding the collection of information
            as described above. By using Net Tahmin platforms and mobile applications,
            Users and Subscribers consent to receive marketing materials, communications
            and promotions from Net Tahmin.</p>

          <p>1.4. Net Tahmin, for the identification of system problems and the immediate
            solution of problems related to Internet sites and systems administered by Net Tahmin
            or connected to Net Tahmin, can save IP addresses, contact information,
            and data related to User and Subscriber email and accounts when necessary,
            and use the data for the above mentioned purposes. This data and information
            can be used by Net Tahmin for the general identification of User and Subscriber
            demographic data, notifications of services and systems changes,
            and marketing of product and promotional offers.</p>

          <p>1.5. Net Tahmin may provide links to other websites through its platforms
            and/or Mobile Applications by Net Tahmin or connected to Net Tahmin. Net Tahmin
            may publish advertisements, promotions, application forms, announcements,
            or surveys by third parties with which it has agreements. Net Tahmin may,
            through these forms, announcements, surveys, advertisements and promotions,
            direct Users and Subscribers to websites, mobile applications, communications
            or other platforms of its third party partners. Net Tahmin bears no liability
            in relation to privacy practices and policies of the third party web sites,
            mobile applications and/or accessed through these links, or for visual,
            textual or any content they contain.</p>

          <p>1.6. User and Subscriber information including, but not limited to, name,
            address, phone number, e-mail address, age, date of birth, city of birth,
            personal relationship, finance, health and other lifestyle data is used to
            identify Users and Subscribers and provide services. Net Tahmin, unless
            otherwise specified in the hereby privacy statement, shall not disclose any
            information to third party partners that are not under contract for business
            services. Net Tahmin gives information on Users and Subscribers to third
            parties in the cases listed below:</p>

          <p>1.6.1. Cases that require legal compliance with existing laws and international
            agreements are in compliance with U.S. law.</p>

          <p>1.6.2. Cases that fulfill the requirements and implementation of the contract
            between Net Tahmin and its Users and Subscribers.</p>

          <p>1.6.3. Cases in which User and Subscriber information is petitioned
            by government and/or judicial authorities for a survey or an investigation
            carried out in accordance with legal procedures.</p>

          <p>1.6.4. Cases in which providing information is necessary for the
            protection of User and Subscriber rights and/or security.</p>

          <p>1.7. Net Tahmin, with hereby notification, undertakes to keep this information
            private and confidential, accepts their confidentiality as an obligation,
            and will take the technical, legal and administrative measures necessary
            for maintaining confidentiality, and preventing all or any part of the
            confidential information from entering the public domain, unauthorized use or
            disclosure to third parties.</p>

          <p>1.8. Net Tahmin can obtain Users and Subscribers information on the
            Mobile Applications and Internet sites by Net Tahmin or connected to
            Net Tahmin by using technical contact file cookies, and information on application
            usage with IP or user account data. Technical contact files, known as cookies,
            are data files which are designed and used to gather statistical information
            on the number of people who have visited the Mobile Application and
            Internet sites, the number of visits, and the duration of the visit,
            for comparisons and to assist in producing commercials and contents with
            dynamic diversity designed specifically for Users and Subscribers.
            Users and Subscribers can always change the settings of their browsers
            if they do not wish to have cookies placed on their computers or
            to receive alerts when receiving this type of file.</p>

          <p>1.9. The information requested from Users and Subscribers in accordance
            with periodic or non-periodic surveys organized within the Mobile Application,
            Internet sites and portals by Net Tahmin can be used for direct marketing to customers,
            statistical analysis, and creating a custom database
            by Net Tahmin and its third party partners.</p>

          <p>1.10 Net Tahmin can change the provisions of the hereby privacy statement as
            deemed necessary, provided it publishes on Mobile Applications and/or
            Internet sites provided by Net Tahmin or connected to Net Tahmin. Changes
            made in the provisions of the privacy statement by Net Tahmin are deemed to
            be in effect on the date of the publication on the website,
            application and its other platforms.</p>

          <h6>Subscription Pricing and Terms</h6>

          <ul>
            <li>
              - Monthly VIP Subscriptions

              Payment will be charged to iTunes Account at confirmation of purchase.
            </li>

            <li>
              - Monthly Subscription automatically renews unless auto-renew is turned
              off at least 24-hours before the end of the current period.

              Account will be charged for monthly subscription renewal within 24-hours
              prior to the end of the current period, and identify the cost of the renewal.
            </li>

            <li>
              - Monthly Subscriptions may be managed by the user and auto-renewal
              may be turned off by going to the user's Account Settings after purchase.

              Any unused portion of a free trial period, if offered, will be forfeited
              when the user purchases a subscription to that publication, where applicable.
            </li>
          </ul>

          <p>E-Mail: info@nettahmin.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style scoped>

</style>
