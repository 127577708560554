<template>
  <header>
    <div id="main-header" class="header-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-3 col-4">
            <div class="logo">
              <a href="/">
                <img src="@/assets/images/NetTahminLogo@1x.svg">
              </a>
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block">
            <nav id="mobile-menu">
              <ul class="main-menu">
<!--                <li><a href="#">Anasayfa</a></li>-->
<!--                <li><a href="#">feature</a></li>-->
<!--                <li><a href="#">service</a></li>-->
<!--                <li><a href="#">team</a></li>-->
<!--                <li><a href="#">testimonial</a></li>-->
              </ul>
            </nav>
          </div>
          <div class="col-lg-3 col-md-9 col-8"></div>
        </div>
        <div class="mobile-menu"></div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped>
.logo img {
  height: 50px;
}
</style>
