<template>
  <div class="home">
    <div class="container">
      <div class="row m-5">
        <div class="col-12 m-auto">
          <div class="row">
            <div class="col-12">
              <p class="app-icon text-center">
                <img src="@/assets/images/net-tahmin-appicon.png">
              </p>
              <h4 class="text-center">Bahis Tahmin Platformu</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <p class="text-right app-download-icon">
                <a href="https://apple.co/3REddwZ">
                  <img style="width: 184px; float: right;"
                       src="@/assets/images/apple-download-black.svg">
                </a>
              </p>
            </div>
            <div class="col-6">
              <p class="text-left app-download-icon">
                <a href='https://play.google.com/store/apps/details?id=com.nettahmin&utm_source=nt-web&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
};
</script>
