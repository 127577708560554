<template>
  <div id="user-agreement">
    <div class="container">
      <div class="row mt-5">
        <div class="col-12 box">
          <h4 class="text-center m-3">Net Tahmin User Agreement</h4>

          <p><b>This agreement pertains to any services provided by Net Tahmin.
            Please read the rules contained in this agreement carefully.
            If you cannot agree to the terms and conditions in the User Agreement,
            do not use or access our website or services.
            These rules may be modified from time to time;
            the date of the most recent revisions will appear on this site.
            Net Tahmin allows members to add information to the site through
            creating member profiles, posting comments and thoughts.
            By using Net Tahmin, you signify you are agreeing to the terms below
            concluding a legally binding contract with Net Tahmin,
            located in Turkey, Istanbul. If you do not agree,
            please do not use our application.</b></p>

          <p><b>The following information explains the terms on which
            Net Tahmin offers you access to our services:</b></p>

          <p><b>1. Registration and Membership</b><br>
            The Service is not intended for users under the age of seventeen (17),
            and Net Tahmin, does not knowingly collect personal information from
            users under the age of 17. Information submitted by such users will not
            knowingly be used, posted, or retained by Net Tahmin. We reserve the
            right to refuse service, terminate accounts, and remove or edit
            content in our sole discretion. Our services may only be used by
            individuals who can form legally binding contracts under applicable law.
            Net Tahmin is in no way responsible for any statements made through
            any of our registered user's profiles or other content added
            to the application by users.</p>

          <p><b>2. Fees and Services</b><br>
            It is free to register on Net Tahmin.
            Any changes to the policy will be posted on Net Tahmin,
            and will take effect within seven (7) days after notice is given on the site.</p>

          <p><b>3. Net Tahmin is a Social Platform</b><br>
            Net Tahmin is a social platform for betting and sports.
            Users can't make betting directly from application or site.
            Net Tahmin, has no control over any comment or information that might
            be shared on the application.
            We also have no control over the truth of the posted comments and thoughts,
            services or any information that is advertised and/or provided by its users.</p>

          <p><b>4. Fraud in Connection with Net Tahmin</b><br>
            Net Tahmin, may suspend or terminate
            your account if we suspect that you have engaged in any form of fraudulent
            activity in connection with the use of the application.
            You may not use a false e-mail address, impersonate any person or entity,
            or otherwise mislead in any form. Net Tahmin, reserves the right to remove or edit
            such content or terminate your account. Net Tahmin, takes no responsibility and
            assumes no liability for any content posted by you or any third party.
            Registered users of Net Tahmin are also prohibited from:
            (1) Providing false or misleading information about themselves
            (2) selling stolen or counterfeit goods,
            (3) using obscene media (photos, video, audio, etc.),
            (3) violating any laws in the United States or Internationally,
            (4) threatening or harassing other members; and
            (5) distributing any materials with any form
            of viruses, trojan horses, worms or any performance that
            will cause harm to other users.</p>

          <p><b>5. Resolution of Disputes</b><br>
            You and Net Tahmin agree that any claim or
            controversy at law or equity that arises out of this User Agreement,
            Privacy Policy or our services shall be resolved through arbitration.
            Arbitration is a form of Alternative Dispute Resolution (ADR) in
            which parties choose an impartial third party to hear and decide the dispute.
            This arbitration hearing will be held location mutually agreed upon by the parties.</p>

          <p><b>6. Copyrights, Trademarks and Patents</b><br>
            All content included on Net Tahmin,
            such as text (comments, reviews, updates to member profiles), graphics,
            logos, button icons, images (resource database), audio clips, data compilations,
            videos and software, is the property of Net Tahmin or its content
            suppliers and are protected by United States and international copyright laws.
            Any violations or misuse will be met with monetary damages.
            Any copyrights associated with Net Tahmin may not be used by anyone other
            than Net Tahmin.</p>

          <p><b>7. Disclosures</b><br>
            Net Tahmin is located in Istanbul, Turkey.
            Email – info@nettahmin.com.</p>

          <p><b>8. Warranty</b><br>
            THE CONTENT, CODE, ACCESS, AND OTHER FEATURES OF
            THIS APPLICATION ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            WARRANTIES OF ACCURACY, RELIABILITY, MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND/OR NON-INFRINGEMENT.
            WE DO NOT WARRANT THAT THIS SERVICE WILL BE UNINTERRUPTED OR
            ERROR-FREE WE RESERVE THE RIGHT TO CORRECT ANY ERRORS OR
            OMISSIONS ON Net Tahmin, EVEN THOUGH WE TAKE MEASURES TO
            ENSURE THAT THERE ARE NO VIRUSES, WORMS, OR ANY OTHER FORM
            OF DESTRUCTIVE MATERIALS ON THIS SITE, WE DO NOT GUARANTEE
            THAT Net Tahmin, OR MATERIALS THAT MAY BE DOWNLOADED FROM Net Tahmin,
            DO NOT CONTAIN THESE MATERIALS. Net Tahmin, IS NOT LIABLE FOR ANY
            DAMAGE OR HARM THAT IS CONNECTED WITH SUCH FEATURES. WHEN ACCESSING
            MATERIALS ON Net Tahmin, YOU ARE DOING SO AT YOUR OWN RISK.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAgreement',
};
</script>

<style scoped>

</style>
