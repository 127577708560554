import HttpService from '@/services/http-service';

export default {
  namespaced: true,
  state: {
    resetPasswordRequest: {
      data: {
        password: '',
        code: '',
      },
      response: {
        result: '',
        errorMessage: '',
      },
    },
  },
  getters: {
    resetPasswordRequest(state) {
      return state.resetPasswordRequest;
    },
  },
  actions: {
    resetPassword({ commit, getters }) {
      return HttpService.post(`${process.env.VUE_APP_API_URL}/api/v1/user/reset-password`, getters.resetPasswordRequest.data)
        .then((res) => {
          if (res.status === 'APPROVED' && res.data === true) {
            commit('SET_RESET_PASSWORD_RESPONSE', {
              result: 'success',
              errorMessage: '',
            });
          } else {
            commit('SET_RESET_PASSWORD_RESPONSE', {
              result: 'fail',
              errorMessage: '',
            });
          }
        })
        .catch((err) => {
          commit('SET_RESET_PASSWORD_RESPONSE', {
            result: 'fail',
            errorMessage: err.message,
          });
        });
    },
  },
  mutations: {
    SET_RESET_PASSWORD_RESPONSE: (state, data) => {
      state.resetPasswordRequest.response = data;
    },
  },
};
