import Vue from 'vue';
import VueRouter from 'vue-router';
import ResetPassword from '@/views/ResetPassword.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import UserAgreement from '@/views/UserAgreement.vue';
import SocialPolicy from '@/views/SocialPolicy.vue';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement,
  },
  {
    path: '/social-media-policy',
    name: 'SocialPolicy',
    component: SocialPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
